import "./App.css";
import React, { useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import NavbarVizio from "./components/navbar/navbar_vizio";
import Footer from "./components/footer/footer";
import Projects from "./components/projects/projects";
import Home from "./components/home/home";
import About from "./components/about/about";
import ReactGA from "react-ga4";
import { BrowserRouter as Router, useLocation } from "react-router-dom";

const TRACKING_ID = "G-LSLHE51QTV"; // your Measurement ID

function App() {
  // useEffect(() => {
  //   ReactGA.initialize(TRACKING_ID);
  //   // Send pageview with a custom path
  //   ReactGA.send({ hitType: "pageview", page: "/home", title: "Home" });
  // }, []);

  const projectsRef = useRef(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const handleProjectsLoaded = () => {
    setDataLoaded(true); // Nastavenie stavu, keď sú dáta načítané
  };

  function scrollToSection(id) {
    const element = document.getElementById(id);
    if (element) {
      const elementRect = element.getBoundingClientRect();
      const elementTop = elementRect.top + window.pageYOffset;

      const offset = window.innerHeight / 2 - elementRect.height / 2;
      if (id !== "kontakt" && id !== "architekti" && id !== "projekty")
        window.scrollTo({
          top: elementTop - offset,
          behavior: "smooth",
        });
      else
        window.scrollTo({
          block: "start",
          top: element.offsetTop - 100,
        });
    }
  }

  function ScrollToSectionOnLoad({ dataLoaded }) {
    const location = useLocation();

    useEffect(() => {
      ReactGA.initialize(TRACKING_ID);
      // Send pageview with a custom path
      ReactGA.send({ hitType: "pageview", page: "/home", title: "Home" });
      if (dataLoaded && location.hash) {
        const id = location.hash.replace("#", "");
        scrollToSection(id);
        if (id !== "kontakt" && id !== "architekti" && id !== "projekty")
          projectsRef.current.handleScrollToProject(id);
      }
    }, [location, dataLoaded]);

    return null;
  }

  return (
    <HelmetProvider>
      <Router>
        {/* <ScrollToTopOnNav /> */}
        <div className="App">
          <Helmet>
            <title>Vizio Architects</title>
            <meta
              name="keywords"
              content="Architektonické služby Bratislava, Architektonický ateliér v Bratislave, Interiérový architekt pre rodinné domy, Interiérový architekt na mieru, Komplexný projekt rodinného domu Bratislava, Architekt pre rodinné domy na Slovensku, Inšpirácie pre slovenskú architektúru, Moderná architektúra na Slovensku, Top architekti na Slovensku, Slovenskí architekti pre moderné projekty, Trendy v dizajne interiéru 2024, Kreatívny dizajn interiéru pre malé byty, Komplexné návrhy interiérov pre byty a domy, Kreatívne návrhy interiérov pre rodinné domy, Inšpirácie pre moderný interiér bytu, 3D vizualizácie pre moderné rodinné domy, Profesionálne vizualizácie domu pre lepšiu predstavu, Tipy a nápady na prerábku bytu, Hľadám architekta na moderný rodinný dom, Hľadám skúseného architekta v Bratislave, Architekt pre komplexný projekt rodinného domu, Architekti Bratislava, Interiérový architekt, Architektonické štúdio, Projekt rodinného domu, Architekt Slovensko, Architektúra Slovensko, Práca architekt, Architekti na Slovensku, Interiérový dizajn, Interiérový dizajn Bratislava, Dizajn interiéru, Bytový dizajn, Návrhy interiérov, Interiér domu, Dizajn domov, Interiér inšpirácie, Realizácie interiérov, Vizualizácie domu, Interiérový dizajn cena, Prerábka bytu inšpirácie, Bytový architekt Bratislava, Architects Bratislava, Architektonické štúdio Bratislava, Architecture design, Hľadám architekta, Architekt projekt, Stredná relevancia:, Ateliér, Arch info, Architecture, Arch itect"
            />
            <meta
              name="description"
              content="Jedinečná a funkčná architektúra pre každého. Zameriavame sa na návrhy rôznych typov
    architektúry - rodinných domov, občianskych stavieb a interiérov na súkromné alebo komerčné účely."
            />
            <link rel="canonical" href="https://www.vizioarchitects.sk/" />
            <link
              rel="shortcut icon"
              href="vizio_logo_48x48.png"
              sizes="48x48"
            />
          </Helmet>
          <ScrollToSectionOnLoad dataLoaded={dataLoaded} />
          <NavbarVizio />
          <Home></Home>
          <Projects ref={projectsRef} onProjectsLoaded={handleProjectsLoaded} />
          <About></About>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
