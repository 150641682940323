import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import myImage from "../../assets/vizio_logo_main.webp";

function Home() {
  return (
    <header id="domov" className="bg-white min-height-25 p-t-56 mb-3">
      <Container>
        <Row>
          <Col>
            <img src={myImage} alt="Logo Vizio" className="logo" />
            {/* <h2 style={{ textTransform: "uppercase" }}>VIZIO</h2>
            <h3 style={{ textTransform: "uppercase" }}>ARCHITECTS</h3> */}
            <p>
              <a
                href="https://www.instagram.com/vizio_architects/"
                target="_blank"
                rel="noreferrer"
                className="p-16"
                aria-label="Instagram"
              >
                <FaInstagram className="color-black me-2 font-size-25" />
              </a>
              <a
                href="https://www.facebook.com/vizio.studio.s"
                target="_blank"
                rel="noreferrer"
                className="p-16"
                aria-label="Facebook"
              >
                <FaFacebook className="color-black me-2 font-size-25" />
              </a>
              <a
                href="whatsapp://send?text=Dobrý deň, mám záujem o služby architekta!&phone=+421917399087"
                target="_blank"
                rel="noreferrer"
                className="p-16"
                aria-label="Whatsapp"
              >
                <FaWhatsapp className="color-black font-size-25" />
              </a>
            </p>
            <h6 style={{ textTransform: "uppercase" }}>
              návrhy, projekty, vizualizácie
            </h6>
          </Col>
        </Row>
      </Container>
    </header>
  );
}

export default Home;
