import React from "react";
import "./pulsing-text.css";

const PulsingText = ({ text, text2, letterIndex }) => {
  const characters = text.split("");

  return (
    <div className="resizing-circle">
      {characters.map((char, i) =>
        i === 4 ? (
          <div key={i} className="circle pulsating"></div>
        ) : (
          <span key={i}>{char}</span>
        )
      )}
      <br></br>
      <span>{text2}</span>
    </div>
  );
};

export default PulsingText;
