import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import myImage from "../../assets/architekti.webp";

const About = () => {
  return (
    <div id="architekti" className="p-t-56">
      <Container>
        <Row>
          <Col>
            <div className="container">
              <img src={myImage} alt="Matka a Peto" className="about-img" />
              <div className="text-container-matka">
                <Row>
                  <Col>
                    <p className="text m-b-0">Martina</p>
                    <p className="m-b-0">architektúra, dizajn, vizualizácie</p>
                  </Col>
                </Row>
              </div>
              <div className="text-container-peto">
                <Row>
                  <Col>
                    <p className="text m-b-0">Peter</p>
                    <p className="m-b-0">autorizovaný architekt</p>
                    <p className="m-b-0">architektúra, dizajn, vizualizácie</p>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="about-view mt-5">
              <p className="about-p">
                Sme architekti venujúci sa kompletnej architektonickej činnosti
                so zameraním na projektovanie rodinných domov, občianskych
                stavieb, súkromných a komerčných interiérov, pri ktorých
                využívame naše dlhoročné skúsenosti v oblasti navrhovania.
                Martina v roku 2018 ukončila Fakultu architektúry STU a od tej
                doby pôsobí ako architekt na rôznych typoch súkromných a
                komerčných projektoch. Peter v roku 2015 ukončil stavebnú
                fakultu STU, dlhoročne pôsobil ako architekt v architektonickom
                ateliéri. V roku 2022 sa stal autorizovaným architektom a členom
                slovenskej komory architektov. S veľkým nadšením a vášňou pre
                architektúru tvoríme funkčné a jedinečné projekty, ktoré sú šité
                na mieru našim klientom a sú prispôsobené ich potrebám a
                preferenciám. Naša kreativita a schopnosť spolupracovať v tíme
                nám umožňuje tvoriť pohodlné domy a interiéry. Pri práci sa
                zameriavame hlavne na detail a inováciu. Vždy hľadáme nové
                možnosti a spôsoby ako vylepšiť naše projekty a poskytnúť tak čo
                najlepšie riešenia. Architektúra a interiérový dizajn, ktorý
                tvoríme, sa preto vyznačujú tým, že je hlavne funkčný a
                estetický. V portfóliu vizioarchitects nájdete mnoho úspešných
                slovenských a zahraničných realizácií.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
