import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { db } from "../../firebase";
import { collection, getDocs, orderBy, query } from "@firebase/firestore";
import { isMobile } from "react-device-detect";
import PulsingText from "../pulsingText/pulsing-text";
import ReactGA from "react-ga4";

const Projects = forwardRef(({ onProjectsLoaded }, ref) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [startLeft, setStartLeft] = useState(0);
  const [startTop, setStartTop] = useState(0);
  const [activeIndex, setActiveIndex] = useState(null);
  const divRefs = useRef([]);
  const imgTitleRef = useRef([]);
  const descRef = useRef([]);
  const localRef = useRef(null);
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const handleDivClick = async (index, title) => {
    if (index !== activeIndex) {
      setActiveIndex(index);
      // if (isMobile) {
      await delay(300);
      divRefs.current[index].scrollBy({
        top: 0,
        left:
          (imgTitleRef.current[index].offsetWidth +
            descRef.current[index].offsetWidth) /
          2,
        behavior: "smooth",
      });
      ReactGA.event({
        category: "Projects",
        action: "click_on_project",
        label: index,
      });
      // }
      const newUrl = `${window.location.origin}${window.location.pathname}#${index}`;
      window.history.pushState({ path: newUrl }, "", newUrl);
      ReactGA.send({ hitType: "pageview", page: index, title: title });
      return;
    }
    setActiveIndex(index);
  };

  useEffect(() => {
    // získajte dáta z kolekcie "projects" v Firebasequery(collection(db, "user"), orderBy('', "desc"), limit(5))
    const projectsDB = query(
      collection(db, "projects"),
      orderBy("place", "asc")
    );
    getDocs(projectsDB).then((data) => {
      const projectTmp = [];
      data.docs.map((doc) => projectTmp.push({ ...doc.data(), id: doc.id }));
      setProjects(projectTmp);
      setLoading(false);
      onProjectsLoaded();
    });
  }, [onProjectsLoaded]);

  function handleMouseDown(e) {
    if (e.button !== 0) {
      return;
    }
    setIsDragging(true);
    setStartX(e.pageX);
    setStartY(e.pageY);
    setStartLeft(e.currentTarget.scrollLeft);
    setStartTop(e.currentTarget.scrollTop);
  }

  function handleMouseMove(e) {
    if (!isDragging || e.button !== 0) {
      return;
    }
    const moveX = e.pageX - startX;
    const moveY = e.pageY - startY;
    e.currentTarget.scrollLeft = startLeft - moveX;
    e.currentTarget.scrollTop = startTop - moveY;
  }

  function handleMouseUp() {
    setIsDragging(false);
  }

  // Expose handleScrollToProject to parent
  useImperativeHandle(ref, () => ({
    handleScrollToProject,
  }));

  const handleScrollToProject = async (index) => {
    setActiveIndex(index);
    // if (isMobile) {
    await delay(300);
    // const element = document.getElementById(index);
    // const elementRect = element.getBoundingClientRect();
    // const offset = window.innerHeight / 2 - elementRect.height / 2;
    // const elementTop = elementRect.top + window.pageYOffset;
    divRefs.current[index].scrollBy({
      top: 0,
      left:
        (imgTitleRef.current[index].offsetWidth +
          descRef.current[index].offsetWidth) /
        2,
      behavior: "smooth",
    });
    ReactGA.event({
      category: "Projects",
      action: "click_on_project",
      label: index,
    });
  };

  const projectCards = projects.map((project) => (
    <div
      className={`container-project w-80-p ${
        project.id === activeIndex ? "active" : ""
      }`}
      key={project.id}
      id={project.id}
      ref={localRef}
    >
      <div
        className="scroll-horizontal"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onClick={() => handleDivClick(project.id, project.title)}
        ref={(el) => (divRefs.current[project.id] = el)}
      >
        <img
          src={`https://fotky.vizioarchitects.sk/${project.folder}/${project.title_image}`}
          alt={project.title_image_alt}
          className={`${project.id !== activeIndex ? "image-one-size" : ""}`}
          ref={(el) => (imgTitleRef.current[project.id] = el)}
        />
        <Container
          className={`project-info ${
            project.id !== activeIndex && isMobile
              ? "hide-description-mobile"
              : ""
          }`}
          ref={(el) => (descRef.current[project.id] = el)}
        >
          <Row>
            <Col>
              <h3>{project.title}</h3>
              {project.obsah.map(function (object, i) {
                if (i % 2 === 0) {
                  return (
                    <div
                      key={object}
                      className="display-block name-var"
                      style={{ textTransform: "lowercase" }}
                    >
                      <strong className="color-grey" key={object}>
                        {object}
                      </strong>
                      <br></br>
                    </div>
                  );
                } else {
                  return (
                    <div key={object} className="display-block">
                      <span key={object}> {object} </span>
                      <br></br>
                    </div>
                  );
                }
              })}
            </Col>
          </Row>
        </Container>
        {project.images.map(function (object, i) {
          if (project.id === activeIndex) {
            return (
              <img
                src={`https://fotky.vizioarchitects.sk/${project.folder}/${object}`}
                key={i}
                alt={project.altImages[i]}
                className="m-r-20"
              />
            );
          } else {
            return false;
          }
        })}
      </div>
    </div>
  ));

  return (
    <div id="projekty" className="p-t-60">
      {loading ? (
        <PulsingText text="VIZIO" text2="ARCHITECTS"></PulsingText>
      ) : (
        projectCards
      )}
    </div>
  );
});

export default Projects;
