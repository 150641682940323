import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";

function Footer() {
  return (
    <footer id="kontakt" className="bg-white">
      <Container>
        <Row>
          <Col>
            <h4 style={{ textTransform: "uppercase" }}>Kontakt</h4>
            <p style={{ textTransform: "uppercase" }}>
              Mám záujem o projekt od vizio architects
            </p>
            <p>
              <span>Ing. Arch. Martina Šajgalíková a Ing. Peter Šajgalík </span>
              <br></br>
              <a
                href="mailto:vizio@vizioarchitects.sk"
                className="no-underline"
              >
                <span> vizio@vizioarchitects.sk</span>
              </a>
              <br></br>
              <a href="tel:+421917399087" className="no-underline">
                <span> +421917399087 </span>
              </a>
              <br></br>
            </p>
            <a
              href="https://www.instagram.com/vizio_architects/"
              target="_blank"
              rel="noreferrer"
              className="p-16"
              aria-label="Instagram"
            >
              <FaInstagram className="color-black me-2 font-size-25" />
            </a>
            <a
              href="https://www.facebook.com/vizio.studio.s"
              target="_blank"
              rel="noreferrer"
              className="p-16"
              aria-label="Facebook"
            >
              <FaFacebook className="color-black me-2 font-size-25" />
            </a>
            <a
              href="whatsapp://send?text=Dobrý deň, mám záujem o služby architekta!&phone=+421917399087"
              target="_blank"
              rel="noreferrer"
              className="p-16"
              aria-label="Whatsapp"
            >
              <FaWhatsapp className="color-black font-size-25" />
            </a>
          </Col>
        </Row>
      </Container>
      <div className="py-3 copyright">
        <Container>
          <p className="m-0 text-center">
            Copyright 2023 &copy; Všetky práva vyhradené. Created by Andrej
            Erdélsky.
          </p>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
