import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import React, { useEffect, useState } from "react";
import logo from "../../assets/vizio_logo.webp";

function NavbarVizio() {
  const [isVisible, setIsVisible] = useState(true);

  const listenToScroll = () => {
    let heightToHideFrom = 56;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
      isVisible && setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll); // eslint-disable-next-line
  }, []);

  return (
    //bg="light" do navbaru
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="bg-white"
      variant="bg-white"
      className="fixed-top"
    >
      <Container>
        {!isVisible && (
          <Navbar.Brand
            href="/"
            style={{ textTransform: "uppercase" }}
            aria-label="VIZIO ARCHITECTS"
          >
            <img src={logo} alt="VIZIO ARCHITECTS" className="nav-logo" />
          </Navbar.Brand>
        )}
        {isVisible && (
          <Navbar.Brand
            href="/"
            style={{ textTransform: "uppercase" }}
            aria-label="VIZIO ARCHITECTS"
          ></Navbar.Brand>
        )}

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="j-content-end">
          <Nav>
            <Nav.Link href="#projekty" style={{ textTransform: "uppercase" }}>
              Projekty
            </Nav.Link>
            <Nav.Link href="#architekti" style={{ textTransform: "uppercase" }}>
              Architekti
            </Nav.Link>
            <Nav.Link href="#kontakt" style={{ textTransform: "uppercase" }}>
              Kontakt
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarVizio;
